@import '../../../assets/styles/shared';

.answerButton {
  color: $dark !important;
  text-transform: none !important;

  /* stylelint-disable selector-class-pattern */
  &:hover,
  &:active,
  &:global(.btn-primary.btn-filled) {
    color: $white !important;
  }
  /* stylelint-enable selector-class-pattern */

  @media (hover: none) {
    &:hover {
      color: $dark !important;
      background-color: $white !important;
    }
  }
}
