@import '../assets/styles/shared';

.background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 50vh;

  background: linear-gradient(0deg, $dusk-blue 0%, $nord-blue 100%);
}
