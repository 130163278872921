@import '../assets/styles/shared';

$easing-exit: $easeInQuad;
$easing-enter: $easeOutQuad;
$timeout: 300ms;

:export {
  timeout: $timeout;
}

.sectionTransitionRight {
  position: relative;

  &.sectionTransitionEnter {
    right: 200px;
    opacity: 0;
  }

  &.sectionTransitionEnterActive {
    right: 0;
    opacity: 1;
    transition: right $timeout $easing-enter, opacity $timeout $easing-enter;
  }

  &.sectionTransitionExit {
    right: 0;
    opacity: 1;
  }

  &.sectionTransitionExitActive {
    right: -200px;
    opacity: 0;
    transition: right $timeout $easing-exit, opacity $timeout $easing-exit;
  }

  &.sectionTransitionExitDone {
    opacity: 0;
  }
}

.sectionTransitionLeft {
  position: relative;

  &.sectionTransitionEnter {
    left: 200px;
    opacity: 0;
  }

  &.sectionTransitionEnterActive {
    left: 0;
    opacity: 1;
    transition: left $timeout $easing-enter, opacity $timeout $easing-enter;
  }

  &.sectionTransitionExit {
    left: 0;
    opacity: 1;
  }

  &.sectionTransitionExitActive {
    left: -200px;
    opacity: 0;
    transition: left $timeout $easing-exit, opacity $timeout $easing-exit;
  }

  &.sectionTransitionExitDone {
    opacity: 0;
  }
}
