@import '../../../../styles/shared';

.videoWrapper {
  iframe {
    // responsive iframe on smaller devices

    @include media-breakpoint-down(lg) {
      width: 100%;
      height: auto;
    }
  }
}
