.roundedCut {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;

  width: 100%;

  /* IE Hack - start */
  height: 0;
  padding: 0 0 1.6678%; // Aspect ratio: 24 / 1439

  &.positionTop {
    top: 0;
    transform: translateY(calc(-100% + 1px));
  }

  &.positionBottom {
    bottom: -0.5px;
  }

  .graphic {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  /* IE Hack - end */
}
