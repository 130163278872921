@import '../../assets/styles/shared';

.pagination-list {
  @include list-unstyled;

  .pagination-list-item {
    position: relative;
    top: 0;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  .pagination-dot {
    position: relative;
    top: 0;

    width: 10px;
    height: 10px;
    padding: 0;

    background-color: transparent;
    border: 2px solid $white;
    border-radius: 6px;
    opacity: 0.33;

    transition: all 0.6s $easeInOutQuad;

    &:not(.disabled) {
      cursor: pointer;
      opacity: 0.66;
    }

    &.active {
      top: 1px;

      width: 12px;
      height: 12px;

      border-color: $nord-green;
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }
}
